<template>
    <div>
        <b-table :sticky-header="true" :data="userAttestations" :striped="true">
            <b-table-column field="user" label="User">
                <div slot="default" slot-scope="{ row }">{{ row.user.firstname }} {{ row.user.lastname }}</div>
            </b-table-column>
            <b-table-column field="company" label="Company">
                <div slot="default" slot-scope="{ row }">
                    {{ row.user.company }}
                </div>
            </b-table-column>
            <b-table-column field="status" label="Status">
                <div slot="default" slot-scope="{ row }">
                    {{ row.status }}
                </div>
            </b-table-column>
            <b-table-column field="expiration" label="Expiration">
                <div slot="default" slot-scope="{ row }">
                    {{ row.expiration }}
                </div>
            </b-table-column>
            <b-table-column field="download" label="Download">
                <div slot="default" slot-scope="{ row }">
                    <button class="btn btn-primary" @click="download(row)">Download</button>
                </div>
            </b-table-column>
            <b-table-column field="actions" label="Actions">
                <div slot="default" slot-scope="{ row }">
                    <button v-if="row.status == 'new'" class="btn btn-success" @click="startValidation(row)">
                        Validate
                    </button>
                </div>
            </b-table-column>
        </b-table>
        <nl-modal :open-modal="openModal" title="Définir une date d'échéance" @hide="openModal = !openModal">
            <v-form @submit="markAsValidate">
                <div class="row">
                    <div class="col">
                        <v-textfield v-model="expiration" label="Expiration" type="date" rules="required" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-secondary">Valider</button>
                    </div>
                </div>
            </v-form>
        </nl-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Download from '../../utils/download'

    export default {
        data: () => ({
            openModal: false,
            toValidate: null,
            expiration: null
        }),
        computed: {
            ...mapGetters('user_attestations', ['userAttestations'])
        },
        mounted() {
            this.listAttestations()
        },
        methods: {
            ...mapActions('user_attestations', {
                listAttestations: 'list',
                updateAttestation: 'update',
                downloadAttestation: 'download'
            }),
            download(attestation) {
                this.downloadAttestation(attestation.id).then(response => {
                    Download.download(response, attestation.user.company)
                })
            },
            startValidation(attestation) {
                this.toValidate = attestation
                this.openModal = true
            },
            markAsValidate() {
                this.updateAttestation({
                    id: this.toValidate.id,
                    status: 'validate',
                    expiration: this.expiration
                }).then(() => {
                    this.toValidate = null
                    this.openModal = null
                    this.expiration = null
                })
            }
        }
    }
</script>

<style></style>
